import { List } from "immutable";
import Router, { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTracking } from "../../utils/useTrackingHook";
import { Container, Row } from "reactstrap";
import { withTheme } from "styled-components";
import config from "../../../config";
import { Breadcrumbs } from "../../modules/breadcrumbs/components/breadcrumbs";
import { RequestData } from "../../services/api/epics/apiRequest";
import {
  ApiLanguages,
  AppFooter,
  AppHeading1,
  AppNavbar,
  AppSubHeadingStyled,
  Booking,
  Policies,
  Reservation,
  Service,
  Settings,
  Theme,
  Venue,
} from "../../shared";
import { Questions } from "../../shared/interfaces/questions";
import {
  createGlobalStyle,
  getColorContrast,
  styled,
} from "../../styles/theme/themeHelper";
import {
  AnalyticsEvent,
  SchedulingType,
  sendAnalyticsEventToParentWindow,
} from "../../utils/analyticsEvent";
import { useGenerateSteps } from "../../utils/breadcrumbsGenerator";
import { calculateDuration } from "../../utils/calculateDuration";
import { getBWID } from "../../utils/getBWID";
import { getLastRouterParamName } from "../../utils/getLastRouterParamName";
import PageRouter, {
  isProductsBeforeStoreStep,
  isVirtualProductsBeforeStoreStep,
  isLiveProductsStep,
} from "../../utils/pageRouter";
import {
  onBackButtonEvent,
  onRouteChangeCompleteEvent,
} from "../analytics/eventCreators";
import Head from "../Head";
import { getLoadScript, Loader } from "../map";
import SummaryContainer from "../summary/SummaryContainer";
import { SUMMARY_WIDTH } from "../summary/SummaryLayout";
import { StepNames, STEP_NAMES } from "../../shared";
import HTMLReactParser, { DOMNode } from "html-react-parser";
import { useSelector } from "react-redux";
import { getIsLiveChat } from "../summary/selectors";
import { getShowBackArrow } from "./utils";
import { getIsAllQuestionsAnsweredViaQueryParams } from "../../utils/getQuestionsFromQueryParams";

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.body.font.family};
    background-color: ${(props) => props.theme.body.backgroundColor};
  }

  input, [role='button'], textarea {
    border-width: 2px;

    &:focus-visible,
    &:focus {
      outline: 2px solid transparent;
      border: 2px solid ${(props) =>
        props.theme.body.buttons.mainBackgroundColor} !important;
    }
  }

  * {
    outline-color: ${(props) => props.theme.body.buttons.mainBackgroundColor};
  }

  button {
    &:focus-visible,
    &:focus {
      outline: 2px solid ${(props) =>
        props.theme.body.buttons.secondaryBackgroundColor} !important;
    }
  }

  .alert-content {
    outline: 2px solid transparent;
    border: 2px solid ${(props) =>
      props.theme.body.buttons.mainBackgroundColor} !important;
  }
`;

const Main = styled.main`
  @media only screen and (max-width: 767px) {
    min-height: calc(100vh - 147px);
  }

  @media only screen and (min-width: 768px) {
    min-height: calc(100vh - 80px - 92px);
  }

  .main-content {
    width: ${`calc(100% - ${SUMMARY_WIDTH}px)`};
    z-index: 1;
  }

  .summary-content {
    width: ${SUMMARY_WIDTH}px;
    padding-left: 25px;
  }

  .load-script {
    .spinner-border {
      color: ${({ theme }) =>
        getColorContrast(theme.body.backgroundColor)} !important;
    }
  }
`;

enum ScriptTarget {
  HEADER = "head",
  FOOTER = "body",
}

const injectScript = (domNode: DOMNode, target: ScriptTarget) => {
  if (domNode.type === "script") {
    const script = document.createElement("script");

    // Set script tag properties
    if (domNode.attribs.async) {
      script.async = domNode.attribs.async;
    }
    if (domNode.attribs.crossorigin) {
      script.crossOrigin = domNode.attribs.crossorigin;
    }
    if (domNode.attribs.defer) {
      script.defer = domNode.attribs.defer;
    }
    if (domNode.attribs.integrity) {
      script.integrity = domNode.attribs.integrity;
    }
    if (domNode.attribs.nomodule) {
      script.noModule = domNode.attribs.nomodule;
    }
    if (domNode.attribs.referrerpolicy) {
      script.referrerPolicy = domNode.attribs.referrerpolicy;
    }
    if (domNode.attribs.src) {
      script.src = domNode.attribs.src;
    }
    if (domNode.attribs.type) {
      script.type = domNode.attribs.type;
    }
    // Set script tag content
    if (domNode.children[0] && domNode.children[0].data) {
      script.text = domNode.children[0].data;
    }

    document[target].appendChild(script);
  } else if (domNode.type === "style") {
    const style = document.createElement("style");

    // Set script tag properties
    if (domNode.attribs.media) {
      style.media = domNode.attribs.media;
    }
    if (domNode.attribs.type) {
      style.type = domNode.attribs.type;
    }
    // Set style tag content
    if (domNode.children[0] && domNode.children[0].data) {
      style.innerHTML = domNode.children[0].data;
    }

    document[target].appendChild(style);
  }
};

export interface BaseProps {
  children: React.ReactElement;
  currentLanguage?: string;
  firstPage?: StepNames;
  languages?: ApiLanguages | void;
  policies?: Policies | void;
  products: List<Service>;
  selectedProductHasAddons?: boolean;
  selectedAddOns: Service[] | void;
  questions: Questions[];
  settings?: Settings;
  settingsReady: boolean;
  singleVenue?: Venue;
  reservation: Reservation | void;
  setCurrentLanguage: (language: string) => void;
  clearCoordinates?: () => void;
  clearProduct?: () => void;
  clearAddOn?: () => void;
  clearAll?: () => void;
  clearVenue: () => void;
  handleReservation: (requestData: RequestData) => void;
  fetchPolicies: (language: string) => void;
  bookingStatus?: string;
  cartAmount: number;
  booking?: Booking;
  singleProduct?: Service;
  showPaymentStep: boolean;
  isPaymentAuthorised: boolean;
  isRedirectedFromAdyen: boolean;
}

export type LayoutPageProps = BaseProps & { theme: Theme };

export const LayoutPage = ({
  children,
  currentLanguage,
  firstPage,
  languages,
  policies,
  products,
  selectedProductHasAddons,
  questions,
  settings,
  settingsReady,
  theme,
  reservation,
  singleVenue,
  setCurrentLanguage,
  clearCoordinates,
  clearProduct,
  clearAddOn,
  clearAll,
  handleReservation,
  bookingStatus,
  cartAmount,
  clearVenue,
  fetchPolicies,
  booking,
  singleProduct,
  selectedAddOns,
  showPaymentStep,
  isPaymentAuthorised,
  isRedirectedFromAdyen,
}: LayoutPageProps) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  const router = useRouter();
  const routerStepName: RegExpMatchArray | null =
    router && getLastRouterParamName(router);

  const currentStepName = useMemo(() => {
    if (routerStepName) {
      return routerStepName[1] as STEP_NAMES;
    }
    return null;
  }, [routerStepName]);

  const [showLanguages, setShowLanguages] = useState(false);
  const [showCart, setShowCart] = useState(!!settings?.showSummaryMobileView);
  const [showSummaryMobile, setShowSummaryMobile] = useState(false);
  const [ready, setReady] = useState(false);

  const isProductsBeforeStore = isProductsBeforeStoreStep(
    router,
    firstPage || ""
  );

  const isVirtualProductsBeforeStore = isVirtualProductsBeforeStoreStep(
    router,
    firstPage || ""
  );

  const isLiveChat =
    useSelector(getIsLiveChat) || isLiveProductsStep(router, firstPage || "");

  const deleteReservation = () => {
    if (
      currentStepName !== STEP_NAMES.SLOTS &&
      reservation &&
      singleVenue &&
      (calculateDuration(
        singleVenue.timezone,
        reservation.expirationDateTime
      ) || 0) > 0
    ) {
      handleReservation({
        method: "DELETE",
        url: `${config.baseApiUrl}/${getBWID()}/reservations/${
          reservation.reservationId
        }`,
      });
    }
  };

  const handleReRouting = (stepName: string | null): boolean => {
    // Show queue selection re-routing
    // Exclude all products before store journey
    const queuesReRoutingValidFor =
      (stepName === STEP_NAMES.SLOTS ||
        stepName === STEP_NAMES.PRODUCTS ||
        stepName === STEP_NAMES.ADDONS ||
        stepName === STEP_NAMES.ADVISORS) &&
      firstPage !== STEP_NAMES.TYPE &&
      firstPage !== STEP_NAMES.PRODUCTS_BEFORE_STORE &&
      firstPage !== STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE;

    if (
      queuesReRoutingValidFor &&
      settings?.showQueueSelection &&
      !router.query.queueId
    ) {
      PageRouter.goToQueues(router.query.venueId as string, true);
      return true;
    }
    return !settings;
  };

  const handleChangeComplete = (url: string) => {
    if (process && process.browser) {
      window.scrollTo(0, 0);

      sendAnalyticsEventToParentWindow({
        schedulingType: SchedulingType.APPOINTMENT,
        analyticsEvent: AnalyticsEvent.ROUTE_CHANGE_COMPLETE,
        analyticsData: {
          url,
        },
      });

      trackEvent(onRouteChangeCompleteEvent(url));
    }
  };

  useEffect(() => {
    // Making sure the scrollbar is reset when changing route
    router.events.on("routeChangeComplete", handleChangeComplete);

    return () => router.events.off("routeChangeComplete", handleChangeComplete);
  }, []);

  useEffect(() => {
    /// Set state for languages selector and cart button
    // If the application changes page, close the summary

    if (!handleReRouting(currentStepName)) {
      setReady(true);

      setShowSummaryMobile(false);

      switch (currentStepName) {
        case STEP_NAMES.VENUES:
          deleteReservation();
          setShowLanguages(true);
          firstPage === STEP_NAMES.PRODUCTS_BEFORE_STORE ||
          firstPage === STEP_NAMES.TYPE
            ? setShowCart(!!settings?.showSummaryMobileView)
            : setShowCart(false);
          break;
        case STEP_NAMES.PRODUCTS:
        case STEP_NAMES.QUEUES:
        case STEP_NAMES.ADDONS:
        case STEP_NAMES.ADVISORS:
        case STEP_NAMES.SLOTS:
          deleteReservation();
          setShowLanguages(true);
          setShowCart(!!settings?.showSummaryMobileView);
          (firstPage === STEP_NAMES.PRODUCTS_BEFORE_STORE &&
            currentStepName === STEP_NAMES.PRODUCTS) ||
          (firstPage === STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE &&
            (currentStepName as string) === STEP_NAMES.PRODUCTS)
            ? setShowCart(false)
            : setShowCart(!!settings?.showSummaryMobileView);
          break;
        case STEP_NAMES.RESCHEDULE:
        case STEP_NAMES.DETAILS:
        case STEP_NAMES.CONFIRMATION:
          setShowLanguages(true);
          setShowCart(!!settings?.showSummaryMobileView);
          break;
        case STEP_NAMES.TYPE:
          clearAll?.();
          deleteReservation();
          setShowCart(false);
          setShowLanguages(true);
          break;
        case STEP_NAMES.BOOKING:
        case STEP_NAMES.CANCELLATION:
          setShowLanguages(true);
          setShowCart(false);
          break;
        default:
          setShowLanguages(true);
          setShowCart(false);
          break;
      }
    }
  }, [currentStepName]);

  const isVenuesDeeplink =
    router.query.from?.includes("/venues") ||
    router.query.from?.includes("/type/venues");

  const showBackArrow = getShowBackArrow({
    firstPage,
    currentStepName,
    selectedProductHasAddons,
    settings,
    isVirtualProductsBeforeStore,
    products,
    showSummaryMobile,
    selectedAddOns,
    isVenuesDeeplink,
    isProductsBeforeStore,
    from: router.query.from,
    isPaymentAuthorised,
    isRedirectedFromAdyen,
  });

  const toggleShowSummaryMobile = () => {
    setShowSummaryMobile(!showSummaryMobile);
  };

  // Show state for summary
  const renderSummary = () => {
    if (
      (currentStepName === STEP_NAMES.VENUES &&
        !(isProductsBeforeStore || firstPage === STEP_NAMES.TYPE)) ||
      currentStepName === STEP_NAMES.BOOKING ||
      currentStepName === STEP_NAMES.CANCELLATION ||
      currentStepName === STEP_NAMES.ERROR ||
      currentStepName === STEP_NAMES.PROFILE ||
      currentStepName === STEP_NAMES.TYPE ||
      !currentStepName ||
      (currentStepName === STEP_NAMES.PRODUCTS &&
        firstPage === STEP_NAMES.PRODUCTS_BEFORE_STORE) ||
      (currentStepName === STEP_NAMES.PRODUCTS &&
        firstPage === STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE) ||
      (currentStepName === STEP_NAMES.LIVE_CHAT_PRODUCTS &&
        firstPage === STEP_NAMES.LIVE_CHAT_PRODUCTS)
    ) {
      return null;
    }

    return <SummaryContainer />;
  };

  const venuePageTitleRef = React.useRef<HTMLElement>();
  const storeFinderHeader = t("storeFinderHeader", {
    defaultValue: "Find your local store and book an appointment",
  });
  const storeFinderSubHeader = t("storeFinderSubHeader", { defaultValue: "" });
  const showVenuePageTitle =
    (storeFinderHeader || storeFinderSubHeader) &&
    currentStepName === STEP_NAMES.VENUES &&
    (firstPage === STEP_NAMES.PRODUCTS_BEFORE_STORE ||
      firstPage === STEP_NAMES.TYPE);

  const renderDesktop = () => {
    const summary = renderSummary();
    return !summary ? (
      <div
        className={"main-content w-100"}
        {...(firstPage === ""
          ? { "aria-labelledby": `breadcrumb-${currentStepName}-title` }
          : {})}
      >
        {children}
      </div>
    ) : (
      <Container className={"py-5 app-content"}>
        <Row>
          <div className={"main-content"}>
            {showVenuePageTitle && (
              <div
                ref={
                  venuePageTitleRef as
                    | React.LegacyRef<HTMLDivElement>
                    | undefined
                }
              >
                <AppHeading1>{storeFinderHeader}</AppHeading1>
                <AppSubHeadingStyled>
                  {storeFinderSubHeader}
                </AppSubHeadingStyled>
              </div>
            )}
            {children}
          </div>
          <aside className={"summary-content"} aria-labelledby="summary-title">
            {summary}
          </aside>
        </Row>
      </Container>
    );
  };

  const renderMobile = () => {
    return (
      <div className="app-content w-100">
        <div className={`${showSummaryMobile ? "d-none" : "w-100"}`}>
          {children}
        </div>
        <div className={`${showSummaryMobile ? "w-100" : "d-none"}`}>
          {renderSummary()}
        </div>
      </div>
    );
  };

  const steps = useGenerateSteps(
    settings,
    questions,
    firstPage,
    router.query.from,
    router.pathname,
    isLiveChat,
    showPaymentStep
  );

  const handleGoBackArrow = () => {
    if (currentStepName === steps[0].stepName) {
      return;
    }

    if (currentStepName === STEP_NAMES.DETAILS) {
      clearProduct?.();
    }

    if (
      (currentStepName === STEP_NAMES.PRODUCTS &&
        firstPage === STEP_NAMES.VENUES) ||
      (currentStepName === STEP_NAMES.ADDONS &&
        !settings?.showProductSelectionIfSingleProduct &&
        products.size <= 1)
    ) {
      clearProduct?.();
      return PageRouter.goToVenues(false, ["by"]);
    }

    if (
      currentStepName === STEP_NAMES.SLOTS ||
      currentStepName === STEP_NAMES.QUEUES ||
      currentStepName === STEP_NAMES.PRODUCTS ||
      currentStepName === STEP_NAMES.ADDONS ||
      (currentStepName === STEP_NAMES.VENUES && firstPage === STEP_NAMES.TYPE)
    ) {
      /* tslint:disable:no-unused-expression */
      clearProduct && clearProduct();
    }

    if (
      currentStepName === STEP_NAMES.ADDONS ||
      currentStepName === STEP_NAMES.PRODUCTS ||
      currentStepName === STEP_NAMES.QUEUES
    ) {
      /* tslint:disable:no-unused-expression */
      clearAddOn && clearAddOn();
      clearCoordinates && clearCoordinates();
    }

    if (
      (currentStepName === STEP_NAMES.SLOTS ||
        currentStepName === STEP_NAMES.ADVISORS) &&
      (isProductsBeforeStore || isVirtualProductsBeforeStore)
    ) {
      clearVenue();
    }

    if (
      currentStepName === STEP_NAMES.DETAILS &&
      isVirtualProductsBeforeStore
    ) {
      clearVenue();
    }

    trackEvent(onBackButtonEvent(currentStepName || ""));

    if (
      ((currentStepName === STEP_NAMES.SLOTS && isVirtualProductsBeforeStore) ||
        (currentStepName === STEP_NAMES.VENUES && isProductsBeforeStore)) &&
      firstPage === STEP_NAMES.TYPE &&
      !settings?.showProductSelectionIfSingleProduct &&
      !selectedProductHasAddons &&
      products.size <= 1
    ) {
      return PageRouter.goToChooseBookingType();
    }

    if (
      currentStepName === STEP_NAMES.ADDONS &&
      !settings?.showProductSelectionIfSingleProduct &&
      products.size <= 1 &&
      firstPage === STEP_NAMES.TYPE &&
      isProductsBeforeStore
    ) {
      return PageRouter.goToChooseBookingType();
    }

    if (
      currentStepName === STEP_NAMES.DETAILS &&
      !settings?.showProductSelectionIfSingleProduct &&
      products.size <= 1 &&
      firstPage === STEP_NAMES.TYPE &&
      isLiveChat
    ) {
      return PageRouter.goToChooseBookingType();
    }

    if (currentStepName === STEP_NAMES.PAYMENT && reservation) {
      const showQuestionStep =
        settings?.showCustomerQuestions &&
        questions?.length &&
        !getIsAllQuestionsAnsweredViaQueryParams(questions) &&
        !settings.combineCustomerDetailsAndYourVisitQuestions;

      if (showQuestionStep) {
        return PageRouter.goToQuestions(reservation.reservationId, true);
      }

      return PageRouter.goToDetails(undefined, reservation.reservationId, true);
    }

    return Router.back();
  };

  const getPreFooter = () => {
    switch (currentStepName) {
      case STEP_NAMES.VENUES:
        return t("storeFinderPreFooter", { defaultValue: "" });
      case STEP_NAMES.QUEUES:
        return t("queuePagePreFooter", { defaultValue: "" });
      case STEP_NAMES.PRODUCTS:
        return t("servicePreFooter", { defaultValue: "" });
      case STEP_NAMES.ADDONS:
        return t("addOnsPreFooter", { defaultValue: "" });
      case STEP_NAMES.ADVISORS:
        return t("staffPreFooter", { defaultValue: "" });
      case STEP_NAMES.SLOTS:
        return t("calendarPreFooter", { defaultValue: "" });
      case STEP_NAMES.DETAILS:
        return t("detailsPreFooter", { defaultValue: "" });
      default:
        return "";
    }
  };

  const showStaffSelectionAfterCalendar =
    settings?.staffOptions.showStaffSelectionAfterCalendar;

  const LoadScript = useMemo(() => getLoadScript(settings), [settings]);
  const hideGoBack = useMemo(() => {
    const deepLinkedAddOnsPage =
      firstPage !== "" &&
      currentStepName === STEP_NAMES.ADDONS &&
      firstPage !== STEP_NAMES.PRODUCTS_BEFORE_STORE &&
      firstPage !== STEP_NAMES.TYPE &&
      firstPage !== STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE;
    const deepLinkedSlotsPage =
      firstPage !== "" &&
      currentStepName === STEP_NAMES.SLOTS &&
      firstPage !== STEP_NAMES.PRODUCTS_BEFORE_STORE &&
      firstPage !== STEP_NAMES.TYPE &&
      firstPage !== STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE;

    return (
      (deepLinkedAddOnsPage || deepLinkedSlotsPage) &&
      !!settings &&
      !settings.showProductSelectionIfSingleProduct &&
      !settings.showQueueSelection &&
      products.size <= 1 &&
      showStaffSelectionAfterCalendar
    );
  }, [currentStepName, settings, products, firstPage]);

  // Handle snippet injection
  React.useEffect(() => {
    if (settings) {
      if (settings.headerScript && !!settings.headerScript.length) {
        HTMLReactParser(settings.headerScript, {
          replace: (domNode: DOMNode) =>
            injectScript(domNode, ScriptTarget.HEADER),
        });
      }

      if (settings.footerScript && !!settings.footerScript.length) {
        HTMLReactParser(settings.footerScript, {
          replace: (domNode: DOMNode) =>
            injectScript(domNode, ScriptTarget.FOOTER),
        });
      }
    }
  }, [settings]);

  React.useEffect(() => {
    if (currentLanguage) {
      fetchPolicies(currentLanguage);
    }
  }, [currentLanguage, fetchPolicies]);

  const capitalizeWord = (titlePart: any) => {
    let str = "";
    if (titlePart !== null && titlePart !== "") {
      str = titlePart.charAt(0).toUpperCase() + titlePart.slice(1);
    }
    return str;
  };

  const renderContent = () =>
    theme && theme.isMobile ? renderMobile() : renderDesktop();

  const showCurrencySelector =
    currentStepName !== STEP_NAMES.TYPE &&
    !booking?.bookingId &&
    !reservation?.reservationId &&
    settings?.showCurrencySelector;

  return (
    <>
      <GlobalStyle />
      <Head titlePart={capitalizeWord(currentStepName)} />
      <Header>
        <AppNavbar
          onGoBack={handleGoBackArrow}
          showGoBack={showBackArrow}
          availableLanguages={languages}
          currentLanguage={currentLanguage}
          onLanguageSelection={setCurrentLanguage}
          showLanguageSelector={showLanguages}
          showCurrencySelector={showCurrencySelector}
          cartAmount={cartAmount}
          onCartToggle={toggleShowSummaryMobile}
          isCartOpen={showSummaryMobile}
          showCart={showCart}
          hideGoBack={hideGoBack}
          firstPage={firstPage}
          clearAll={clearAll}
        />
      </Header>
      <Main>
        <Breadcrumbs
          onGoBack={handleGoBackArrow}
          currentStepName={currentStepName}
          products={products}
          selectedProductHasAddons={selectedProductHasAddons}
          steps={steps}
          availableLanguages={languages}
          currentLanguage={currentLanguage}
          onLanguageSelection={setCurrentLanguage}
          showLanguageSelector={showLanguages}
          showGoBack={showBackArrow && !hideGoBack}
          settings={settings}
          showProductSelectionIfSingleProduct={
            !!settings && settings.showProductSelectionIfSingleProduct
          }
          showCurrencySelector={showCurrencySelector}
          showQueueSelection={!!settings && settings.showQueueSelection}
          bookingStatus={bookingStatus}
          booking={booking}
          singleProduct={singleProduct}
          firstPageProp={firstPage}
        />
        {currentLanguage && theme && ready && (
          <LoadScript
            id="script-loader"
            googleMapsApiKey={config.googleApiKey}
            libraries={["drawing", "visualization", "places"]}
            language={currentLanguage}
            loadingElement={<Loader />}
          >
            {renderContent()}
          </LoadScript>
        )}
        {!ready && settingsReady && !settings && renderContent()}
      </Main>
      <AppFooter
        policies={policies}
        showLogo={!!settings && settings.showQudiniBrand}
        preFooter={getPreFooter()}
        showCart={showCart}
        availableLanguages={languages}
        currentLanguage={currentLanguage}
        onLanguageSelection={setCurrentLanguage}
        showLanguageSelector={showLanguages}
        showCurrencySelector={showCurrencySelector}
      />
    </>
  );
};

export default withTheme(LayoutPage);
