import { Map } from "immutable";
import { Reducer } from "redux";
import {
  SET_ADYEN_SESSION_DETAILS,
  SET_ADYEN_PAYMENT_STATUS,
  SET_CUSTOMER_PAYMENT_DETAILS,
  CLEAR_CUSTOMER_PAYMENT_DETAILS,
} from "./constants";

export const initialState = Map({});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_PAYMENT_DETAILS:
      state = state.set("paymentDetails", action.paymentDetails);
      return state;

    case CLEAR_CUSTOMER_PAYMENT_DETAILS:
      state = state.set("paymentDetails", initialState);
      return state;

    case SET_ADYEN_SESSION_DETAILS:
      state = state.set("adyenSessionDetails", action.adyenSessionDetails);
      return state;

    case SET_ADYEN_PAYMENT_STATUS:
      state = state.set("adyenPaymentStatus", action.adyenPaymentStatus);
      return state;

    default: {
      return state;
    }
  }
};

export { reducer as paymentReducer };
