import { CustomerPaymentDetails } from "../../shared";
import { ApplicationState } from "../../store";
import { localizeCurrency } from "../../utils/currency";
import {
  getSelectedAddOns,
  getSingleProductResponse,
} from "../products/selectors";

export const renderPrice = (price?: number, currency?: string) => {
  return price && currency ? localizeCurrency(price, currency) : price;
};

// to be replaced with a value from reservation response
export const getRequiredPaymentAmount = (
  state: ApplicationState
): string | undefined => {
  const service = getSingleProductResponse(state);
  const selectedAddOns = getSelectedAddOns(state);

  if (!service) {
    return undefined;
  }

  const servicePrice = Number(service.price) || 0;
  const addOnsPrice = selectedAddOns
    ? selectedAddOns.reduce((prev, current) => {
        return prev + (Number(current.price) || 0);
      }, 0)
    : 0;

  const total = servicePrice + addOnsPrice;

  if (total) {
    const totalPrice = renderPrice(total, service.currencyCode);
    return totalPrice ? `${totalPrice}` : undefined;
  }

  return undefined;
};

export const getCustomerPaymentDetails = (
  state: ApplicationState
): CustomerPaymentDetails | void => {
  if (!state.customerPaymentDetails.get("paymentDetails")) {
    return;
  }

  return state.customerPaymentDetails.get("paymentDetails");
};

const getCompletePayment = (state: ApplicationState) => {
  return state.api.get("PAYMENT");
};

export const isLoadingCompletePayment = (state: ApplicationState): boolean => {
  if (!getCompletePayment(state)) {
    return false;
  }

  return getCompletePayment(state).get("isLoading");
};

export const getCompletePaymentResponse = (state: ApplicationState) => {
  const payload = getCompletePayment(state)?.get("payload");

  return payload
    ? getCompletePayment(state).getIn(["payload", "response"])
    : undefined;
};

const getAdyenSession = (state: ApplicationState) => {
  return state.api.get("ADYEN_SESSION");
};

export const getAdyenSessionResponse = (state: ApplicationState) => {
  const payload = getAdyenSession(state)?.get("payload");

  return payload
    ? getAdyenSession(state).getIn(["payload", "response"])
    : undefined;
};

const getAdyenStatus = (state: ApplicationState) => {
  return state.api.get("ADYEN_STATUS");
};

export const getAdyenStatusResponse = (state: ApplicationState) => {
  const payload = getAdyenStatus(state)?.get("payload");

  return payload
    ? getAdyenStatus(state).getIn(["payload", "response"])
    : undefined;
};

export const getAdyenStatusError = (state: ApplicationState) => {
  const payload = getAdyenStatus(state)?.get("error");

  return payload
    ? getAdyenStatus(state).getIn(["error", "response"])
    : undefined;
};
