import { List } from "immutable";
import {
  FirstSlot,
  MonthlyAvailabilitySlot,
  Service,
  SlotsCalendar,
  STEP_NAMES,
  VenueSlot,
} from "../../shared";
import { ApplicationState } from "../../store";
import { getFirstPage } from "../layout/selectors";

const getFirstAvailableSlotApi = (state: ApplicationState) => {
  return state.api.get("FIRST_AVAILABLE_SLOT");
};

const getSlotsApi = (state: ApplicationState) => {
  return state.api.get("SLOTS");
};

const getSingleVenueApi = (state: ApplicationState) => {
  return state.api.get("SINGLE_VENUE");
};

const getSingleProductApi = (state: ApplicationState) => {
  return state.api.get("SINGLE_PRODUCT");
};

export const isLoading = (state: ApplicationState) => {
  const firstPage = getFirstPage(state);
  if (
    !getFirstAvailableSlotApi(state) ||
    !getSlotsApi(state) ||
    (!getSingleVenueApi(state) &&
      firstPage !== STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE &&
      firstPage !== STEP_NAMES.TYPE) ||
    !getSingleProductApi(state)
  ) {
    return true;
  }

  const currentState =
    getFirstAvailableSlotApi(state).get("isLoading") ||
    getSlotsApi(state).get("isLoading") ||
    (firstPage !== STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE &&
    firstPage !== STEP_NAMES.TYPE
      ? getSingleVenueApi(state).get("isLoading")
      : false) ||
    getSingleProductApi(state).get("isLoading");

  return currentState;
};

export const isLoadingSlots = (state: ApplicationState) => {
  if (!getSlotsApi(state)) {
    return true;
  }

  return getSlotsApi(state).get("isLoading");
};

export const getResponse = (state: ApplicationState) => {
  if (!getSlotsApi(state) || !getSlotsApi(state).get("payload")) {
    return;
  }

  return getSlotsApi(state).getIn(["payload", "response", "slotsCalendar"]);
};

export const getCursor = (state: ApplicationState) => {
  if (!getSlotsApi(state) || !getSlotsApi(state).get("payload")) {
    return;
  }

  return getSlotsApi(state).getIn([
    "payload",
    "response",
    "paging",
    "nextCursor",
  ]);
};

export const getBookingDate = (state: ApplicationState) => {
  if (!state.slots.get("date")) {
    return;
  }

  return state.slots.get("date");
};

export const getBookingVirtualVenueId = (state: ApplicationState) => {
  if (!state.slots.get("venueId")) {
    return;
  }

  return state.slots.get("venueId");
};

export const isLoadingNearbyVenues = (state: ApplicationState): boolean => {
  const otherVenues = state.api.get("OTHER_VENUES");

  return !otherVenues || otherVenues.get("isLoading");
};

export const isLoadingNearbyVenuesSearch = (
  state: ApplicationState
): boolean => {
  const otherVenuesSearch = state.api.get("OTHER_VENUES_SEARCH");

  return !otherVenuesSearch || otherVenuesSearch.get("isLoading");
};

export const getNearbyVenues = (
  state: ApplicationState
): undefined | VenueSlot[] => {
  const otherVenues = state.api.get("OTHER_VENUES");

  if (!otherVenues || !otherVenues.get("payload")) {
    return;
  }
  return otherVenues.getIn(["payload", "response", "venueSlots"]);
};

export const getNearbyVenuesSearch = (
  state: ApplicationState
): undefined | VenueSlot[] => {
  const otherVenuesSearch = state.api.get("OTHER_VENUES_SEARCH");

  if (!otherVenuesSearch || !otherVenuesSearch.get("payload")) {
    return;
  }

  return otherVenuesSearch.getIn(["payload", "response", "venueSlots"]);
};

export const getSlotsAggregate = (
  state: ApplicationState
): List<SlotsCalendar> => {
  if (!getSlotsApi(state) || !getSlotsApi(state).get("paginated")) {
    return List();
  }

  return getSlotsApi(state).get("paginated");
};

const getSlotsDatepickerApi = (state: ApplicationState) => {
  return state.api.get("SLOTS_DATEPICKER");
};

export const getSlotsDatepicker = (
  state: ApplicationState
): List<{ date: string; slots: MonthlyAvailabilitySlot[] }> => {
  if (
    !getSlotsDatepickerApi(state) ||
    !getSlotsDatepickerApi(state).get("paginated")
  ) {
    return List();
  }

  return getSlotsDatepickerApi(state).get("paginated");
};

export const getSlotsDatepickerLoading = (state: ApplicationState): boolean => {
  if (
    !getSlotsDatepickerApi(state) ||
    !getSlotsDatepickerApi(state).get("isLoading")
  ) {
    return false;
  }
  return getSlotsDatepickerApi(state).get("isLoading");
};

export const getFirstAvailableSlot = (
  state: ApplicationState
): FirstSlot | undefined => {
  const firstAvailableSlot = getFirstAvailableSlotApi(state);

  if (!firstAvailableSlot) {
    return undefined;
  }
  const firstSlots: FirstSlot[] | undefined = firstAvailableSlot.getIn([
    "payload",
    "response",
    "firstSlots",
  ]);

  if (!firstSlots) {
    return undefined;
  }

  return firstSlots[0] || { startTimeSlot: "0" };
};

export const getBookingId = (state: ApplicationState) => {
  if (!state.api.get("BOOKING")) {
    return;
  }

  return state.api.get("BOOKING").getIn(["payload", "response", "bookingId"]);
};

export const getSelectedStaffId = (
  state: ApplicationState
): string | undefined => {
  if (!state.api.get("SINGLE_ADVISOR")) {
    return;
  }

  return state.api.get("SINGLE_ADVISOR").getIn(["payload", "response", "id"]);
};

export const getNoPreferenceAdvisor = (state: ApplicationState): boolean => {
  if (!state.slots) {
    return false;
  }

  return state.slots.get("isNoPreferenceAdvisor");
};

export const getToastId = (state: ApplicationState): string => {
  return state.slots.get("toastId");
};

export const getProductsLayoutType = (state: ApplicationState): string => {
  return state.products.get("layoutType");
};

export const getAddOns = (state: ApplicationState): undefined | Service[] => {
  const addOns = state.api.get("ADDONS");

  if (!addOns?.get("payload")) {
    return;
  }

  return addOns.getIn(["payload", "response", "productAddOns"]);
};
